import React from 'react';
import { Flex } from '@chakra-ui/core';
import { colors } from '@theme';
import { H2, H4 } from '@typography';

import ContentContainer from '../ContentContainer';
import { JAHelpfulResources } from '../../lib/consts';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';
import { APP_CONTENT_DESKTOP_WIDTH } from '../../styles/sizes';
import ContentResource from '../home/ContentResource';

const HelpfulResources = () => {
  return (
    <Flex className="helpful-resources">
      <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
        <Flex py={[10, 24]} direction="column" justify="center" align="center">
          <Flex
            justify="center"
            align="center"
            direction="column"
            textAlign="center"
            mb={[10, 16]}
            px={[4, 0]}
          >
            <H2>More helpful resources</H2>
            <DesktopOnly>
              <H4 mt={4} center>
                Here's how to leverage personal accounts when combining finances:
              </H4>
            </DesktopOnly>
            <MobileOnly>
              <H4 mt={4} center>
                Here's how to leverage personal
                <br />
                accounts when combining finances:
              </H4>
            </MobileOnly>
          </Flex>

          <Flex
            pt={[6, '36px']}
            paddingX={['12px']}
            direction={['column', 'row']}
            width={['100%']}
            justify="space-between"
            alignItems={'flex-start'}
            style={{ gap: '24px' }}
          >
            {JAHelpfulResources.map(
              ({ title, image, description, ctaUrl, titleBreak }) => (
                <ContentResource
                  key={`cr-${title}`}
                  customTitleSize="24px"
                  title={title}
                  description={description}
                  imageSource={image}
                  ctaUrl={ctaUrl}
                  titleBreak={titleBreak}
                />
              ),
            )}
          </Flex>
        </Flex>
      </ContentContainer>
    </Flex>
  );
};

export default HelpfulResources;
