import React from 'react';
import { Flex, Text, Button } from '@chakra-ui/core';

import theme, { colors } from '@theme';
import ContentContainer from '../../components/ContentContainer';
import { zjcWebUrl, cloudfrontBaseUrl } from '../../lib/consts';
import { APP_CONTENT_DESKTOP_WIDTH } from '../../styles/sizes';
import { marginRight } from 'styled-system';

const backgroundImage = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-anchor.jpg`;

const Anchor = ({ getStarted }) => {
  let getStartedUrl;

  if (getStarted === 'personal') {
    getStartedUrl = 'https://next.askzeta.com/';
  } else {
    getStartedUrl = zjcWebUrl;
  }

  return (
    <>
      <Flex
        align={['flex-start', 'center']}
        background={`url(${backgroundImage}) top center no-repeat`}
        backgroundPosition={['25% 0%', 'center']}
        backgroundSize="cover"
        minHeight="800px"
        style={{
          imageRendering: '-webkit-optimize-contrast',
        }}
      >
        <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH} width={'100%'}>
          <Flex
            direction="column"
            align={['center', 'flex-start']}
            paddingX={['24px']}
            paddingY={['15%', 0]}
            width={'100%'}
          >
            <Text
              textAlign={['center', 'left']}
              fontSize={['32px', '48px']}
              fontWeight={theme.fontWeights.semibold}
              as="h3"
              color={colors.white}
            >
              Live The Life You Want
            </Text>
            <Text
              fontSize={['16px', '18px']}
              pt={2}
              mt={[0, 3]}
              textAlign={['center', 'left']}
              color={colors.white}
            >
              Take the first step and achieve
              <br />
              your goals together as a family.
            </Text>
            <Flex pt={3} mt={6} justify={['center', 'left']}>
              <Button
                height="3.5rem"
                paddingX={'3rem'}
                border="1px"
                backgroundColor={colors.greenLight}
                _hover={{
                  backgroundColor: colors.darkGreenHover,
                }}
                onClick={() => {
                  window.location.href = zjcWebUrl;
                }}
              >
                Get started
              </Button>
            </Flex>
          </Flex>
        </ContentContainer>
      </Flex>
    </>
  );
};

export default Anchor;
