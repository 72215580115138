import React from 'react';
import { Flex, Image, useMediaQuery, Box } from '@chakra-ui/core';

import { H2, H4 } from '@typography';
import ContentContainer from '../ContentContainer';
import { cloudfrontBaseUrl } from '../../lib/consts';
import {
  APP_CONTENT_WIDE_WIDTH,
  APP_CONTENT_SMALL_DESKTOP_WIDTH,
  APP_CONTENT_DESKTOP_WIDTH,
} from '../../styles/sizes';

const Reviews = () => {
  const [isMobile] = useMediaQuery(`(max-width: ${APP_CONTENT_SMALL_DESKTOP_WIDTH})`);
  const FamiliesHeartZeta = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-peopleLoveZeta.svg`;
  const FamiliesLoveZeta1 = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-FamiliesLoveZeta1.svg`;
  const FamiliesLoveZeta2 = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-FamiliesLoveZeta2.svg`;
  const FamiliesLoveZeta3 = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-FamiliesLoveZeta3.svg`;
  const FamiliesLoveZeta4 = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-FamiliesLoveZeta4.svg`;
  const FamiliesLoveZeta5 = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-FamiliesLoveZeta5.svg`;

  return (
    <Box
      backgroundSize={'cover'}
      backgroundPosition={'center'}
      backgroundRepeat={'no-repeat'}
      background={[
        `radial-gradient(90% 90% at -10% -10%, #98D3B666 0%, transparent), 
      radial-gradient(90% 90% at top right, #F2D02244 0%, transparent), 
      radial-gradient(90% 90% at 90% 50%, #FAE6E666 0%, transparent), 
      radial-gradient(90% 90% at bottom left, #6EDAF266 0%, transparent)`,
        `radial-gradient(90% 90% at top left, #98D3B666 0%, transparent), 
        radial-gradient(90% 90% at top right, #F2D02266 0%, transparent), 
        radial-gradient(90% 90% at right, #FAE6E666 0%, transparent), 
        radial-gradient(90% 90% at bottom left, #6EDAF266 0%, transparent)`,
        `radial-gradient(
          65% 90% at 100% 90%,
          #fae4e411 25%,
          #fae4e455 70%,
          transparent
        ),
        radial-gradient(90% 90% at top left, #98d3b666 0%, transparent),
        radial-gradient(60% 50% at top right, #6edaf255 0%, transparent),
        radial-gradient(75% 90% at bottom right, #fae4e466 10%, transparent),
        radial-gradient(90% 90% at bottom left, #f2d02255 0%, transparent);`,
        ` radial-gradient(
          65% 90% at 100% 90%,
          #fae4e411 25%,
          #fae4e455 70%,
          transparent
        ),
        radial-gradient(90% 90% at top left, #98d3b666 0%, transparent),
        radial-gradient(60% 50% at top right, #6edaf255 0%, transparent),
        radial-gradient(75% 90% at bottom right, #fae4e466 10%, transparent),
        radial-gradient(90% 90% at bottom left, #f2d02255 0%, transparent);`,
      ]}
    >
      <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH} minWidth="auto" width={'100%'}>
        <Flex paddingY={[12, 24, 24, 24]} direction="column" justify={'center'}>
          <Flex
            justify="center"
            align="center"
            direction="column"
            textAlign="center"
            mb={[10, 10, 16]}
            px={4}
          >
            <H2>Families 🖤 Zeta</H2>
            <H4 center mt={4} maxWidth={APP_CONTENT_SMALL_DESKTOP_WIDTH}>
              Zeta's aim is to help you win, no matter what stage you are at in your life.
              Here's what the experts have to say.
            </H4>
          </Flex>

          <Flex
            justify="center"
            alignItems="center"
            alignSelf={['center', 'center', 'auto']}
            direction={['column', 'column', 'row']}
            paddingX={['24px']}
            paddingTop="24px"
            mb={[0, 8]}
            width={'100%'}
          >
            <Flex
              flexDirection={['column', 'column', 'row']}
              pt="24px"
              align={'center'}
              width={'100%'}
              style={{ gap: '12px' }}
            >
              <Flex width="100%" flexDirection={'column'} style={{ gap: '12px' }}>
                <Image src={FamiliesLoveZeta1} width="100%" />
                <Image src={FamiliesLoveZeta2} width="100%" />
              </Flex>
              <Flex width="100%" flexDirection={'column'} style={{ gap: '12px' }}>
                <Image src={FamiliesLoveZeta3} width="100%" />
              </Flex>
              <Flex width="100%" flexDirection={'column'} style={{ gap: '12px' }}>
                <Image src={FamiliesLoveZeta4} width="100%" />
                <Image src={FamiliesLoveZeta5} width="100%" />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </ContentContainer>
    </Box>
  );
};

export default Reviews;
