import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/core';
import { colors } from '@theme';
import { cloudfrontBaseUrl } from '../../lib/consts';
import TextWithImageRow from '../zeta/TextWithImageRow';

const KeyFeatures = () => {
  const FamilyFeatures1 = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-features1.jpg`;
  const FamilyFeatures2 = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-features2.jpg`;
  const FamilyFeatures3 = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-features3.jpg`;
  const FamilyFeatures4 = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-features4.jpg`;
  const FamilyFeatures1Mobile = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-features1-mobile-new.png`;
  const FamilyFeatures2Mobile = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-features2-mobile.png`;
  const FamilyFeatures3Mobile = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-features3-mobile-new.png`;
  const FamilyFeatures4Mobile = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-features4-mobile.png`;

  return (
    <Flex paddingBottom={[0]} paddingX={[0, 16, 16, 8]} direction="column">
      <Flex
        justify="center"
        align="center"
        direction="column"
        textAlign="center"
        mb={[8]}
        px={[4, 0]}
      >
        <Text
          color={[colors.darkGray, colors.black]}
          fontSize={['32px', '48px']}
          fontWeight={'600'}
          lineHeight={'1.2'}
        >
          Family account key features
        </Text>

        <Flex
          mt="12px"
          paddingX={[4, 0]}
          justify="center"
          color={colors.grayGray900}
          fontSize={['16px', '24px']}
        >
          Designed to help your whole family bank together easily.
        </Flex>
      </Flex>

      <TextWithImageRow
        imageOnRight={true}
        image={FamilyFeatures1}
        tag="add members"
        title={`Add up to 5 members to your account`}
        body={`Whether you're a single parent with two teens, young family with a nanny, or an inter-generational household, Zeta accounts adapt to your family. Add up to 5 members on your account.`}
        fullWidthMobileBackground={true}
        smallerText={true}
        mobileBackground={'#FDF3F3'}
        mobileImage={FamilyFeatures1Mobile}
      />

      <TextWithImageRow
        imageOnRight={false}
        image={FamilyFeatures2}
        tag="controls"
        title={`Customize permissions`}
        body={`Control exactly who has access to what. You can set custom permissions for each member on your account including only giving them access to a card or expanding that access to the whole account.`}
        smallerText={true}
        mobileBackground={'#FDF3F3'}
        mobileImage={FamilyFeatures2Mobile}
      />

      <TextWithImageRow
        imageOnRight={true}
        image={FamilyFeatures3}
        tag="zeta pay"
        title={`Instantly pay anyone`}
        body={`Pay any member of your household instantly (for free) via Zeta Pay. And if they're not a Zeta member, you can still use ACH or wires.`}
        smallerText={true}
        mobileBackground={'#FDF3F3'}
        mobileImage={FamilyFeatures3Mobile}
      />

      <TextWithImageRow
        imageOnRight={false}
        image={FamilyFeatures4}
        tag="households"
        title={`Works for every relationship in your life`}
        body={`Manage your entire household on Zeta. Add or remove members, set controls, or manage your subscription.`}
        smallerText={true}
        mobileBackground={'#FDF3F3'}
        mobileImage={FamilyFeatures4Mobile}
      />
    </Flex>
  );
};

export default KeyFeatures;
